<template>
  <span v-if="['text', 'number', 'readOnly'].includes(model.frontType)">{{
    value === false
      ? "Όχι"
      : value === true
      ? "Ναι"
      : model.filterType === "date"
      ? new Date(value).toLocaleString("el-GR", { timeZone: "Europe/Athens" })
      : model.options?.find(v => v.value === value)?.name || value
  }}</span>
  <span v-if="model.frontType === 'password'"> ********* </span>
  <span v-if="model.frontType === 'dropdown'">{{
    model.options?.find(v => v.value === value)?.name || value
  }}</span>
  <span v-if="model.frontType === 'multiselect'">{{
    value.map(v => model.options?.find(v2 => v2.value === v)?.name || v).join(", ")
  }}</span>
  <div v-if="model.frontType === 'checkboxes'">
    <div v-for="option in model.options" :key="option.name" class="flex gap-2 mt-1">
      <Checkbox
        v-model="checkboxesValues"
        :value="option.value"
        :disabled="true"
        :trueValue="checkboxesValues?.includes(option.value)"
      />
      <div>{{ option.name }}</div>
    </div>
  </div>
  <template v-if="model.frontType === 'list'">
    <template v-for="(singleValue, index) in value" :key="index">
      <div v-if="typeof singleValue === 'object'" class="grid gap-3 mt-auto">
        <div v-for="property in model.properties" :key="property" class="col-3 p-0">
          {{ property.name }}: {{ singleValue[property.name] }}
        </div>
      </div>
      <div v-else>
        {{ singleValue }}
      </div>
    </template>
  </template>
</template>

<script>
import Checkbox from "primevue/checkbox"

export default {
  props: ["model", "value"],
  data() {
    return { checkboxesValues: this.value }
  },
  watch: {
    value: {
      handler(value) {
        this.checkboxesValues = value
      }
    }
  },

  components: { Checkbox }
}
</script>
