<template>
  <DataTable
    v-show="localData !== null"
    v-if="localColumns !== null"
    :value="localData"
    :paginator="true"
    :rows="10"
    responsiveLayout="scroll"
    dataKey="id"
    v-model:filters="localColumns"
    filterDisplay="row"
    @row-click="rowClick($event.data)"
    ref="dt"
    @filter="onFilter($event)"
  >
    <template #header>
      <div class="text-right">
        <vue-excel-xlsx
          class="p-button p-button-secondary"
          :data="filteredData"
          :columns="
            Object.entries(localColumns).reduce((acc, item) => {
              acc.push({ label: item[1]['niceName'], field: item[0] })
              return acc
            }, [])
          "
          :file-name="'Export'"
          :file-type="'xlsx'"
          :sheet-name="'sheetname'"
        >
          Εξαγωγή
        </vue-excel-xlsx>
      </div>
    </template>
    <template #empty> Δε βρέθηκαν δεδομένα. </template>

    <template v-for="(column, key) in localColumns" :key="key">
      <Column
        v-if="!column.hideInListView"
        :filterField="key"
        :header="column.niceName"
        :sortable="true"
        :sortField="key"
        :dataType="column.filterType"
      >
        <template #body="{ data }">
          <template v-if="column.filterType !== 'boolean'">{{ data[key] }}</template>
          <i
            v-if="column.filterType === 'boolean'"
            class="pi"
            :class="{
              'true-icon pi-check-circle': data[key] === 'Ναι',
              'false-icon pi-times-circle': data[key] === 'Όχι'
            }"
          ></i>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-if="['text', 'number'].includes(column.frontType)"
            :ref="key"
            :type="column.frontType"
            v-model="filterModel.value"
            @input="
              () => {
                filterCallback()
                focusOnInput(key)
              }
            "
            class="p-column-filter"
            placeholder="Αναζήτηση"
          />
          <MultiSelect
            v-if="
              ['dropdown', 'checkboxes', 'list'].includes(column.frontType) || column.filterType === 'balance'
            "
            v-model="filterModel.value"
            @change="filterCallback()"
            :options="
              column.filterType === 'balance'
                ? this.balanceLevels
                : undefined === column.options[0]?.name
                ? column.options.map(v => ({ name: v, value: v }))
                : column.options
            "
            optionLabel="name"
            :optionValue="column.filterType === 'balance' ? 'value' : 'name'"
            placeholder="Αναζήτηση"
          >
            <template #option="slotProps">
              <span>{{ slotProps.option.name }}</span>
            </template>
          </MultiSelect>
          <DateFromTo
            v-if="column.filterType === 'date'"
            v-model="filterModel.value"
            @update:modelValue="filterCallback"
          />
          <TriStateCheckbox
            v-if="column.filterType === 'boolean'"
            v-model="filterModel.value"
            @update:modelValue="
              value => {
                if (value === true) {
                  filterModel.value = false
                } else if (value === false) {
                  filterModel.value = null
                } else {
                  filterModel.value = true
                }
                filterCallback()
              }
            "
          />
        </template>
      </Column>
    </template>
  </DataTable>
</template>

<script>
import Column from "primevue/column"
import InputText from "primevue/inputtext"
import DataTable from "primevue/datatable"
import TriStateCheckbox from "primevue/tristatecheckbox"
import MultiSelect from "primevue/multiselect"
import DateFromTo from "@/components/DateFromTo"
import { FilterMatchMode, FilterService } from "primevue/api"
import useSettings from "@/states/settings"
import { watch } from "vue"

FilterService.register("multiselect", (a, b) => {
  return b === null || (typeof b === "object" && !Object.keys(b).length) || b.includes(a)
})
FilterService.register("balance", (a, b) => {
  const unformattedBalance = parseFloat(a.replace("€", "").split(".")[0].replace(",", ""))
  return (
    b === null ||
    (typeof b === "object" && !Object.keys(b).length) ||
    (b?.[0]?.[0] <= unformattedBalance && unformattedBalance <= b?.[0]?.[1])
  )
})
FilterService.register(
  "checkbox",
  (a, b) => b === null || (a === "Ναι" && b === true) || (a === "Όχι" && b === false)
)
FilterService.register(
  "dates",
  (a, b) => (!b?.[0] || new Date(a) >= b?.[0]) && (!b?.[1] || new Date(a) <= b?.[1])
)

export default {
  props: ["data", "columns", "rowClick"],
  data() {
    return {
      localColumns: null,
      localData: null,
      balanceLevels: null,
      filteredData: null
    }
  },
  mounted() {
    const { settings } = useSettings()
    watch(
      settings,
      settings => {
        if (Object.keys(settings).length > 0) {
          this.balanceLevels = settings.balanceLevels?.map(v => ({
            name: `Από ${v["Από"]} έως ${v["Έως"]}`,
            value: [v["Από"], v["Έως"]]
          }))
        }
      },
      { immediate: true }
    )
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        this.updateData(value, this.localColumns)
      }
    },
    columns: {
      immediate: true,
      handler(value) {
        if (value) {
          this.localColumns = Object.entries(value).reduce((acc, obj) => {
            acc[obj[0]] = {
              ...obj[1],
              value: null,
              matchMode: ["dropdown", "checkboxes"].includes(obj[1].frontType)
                ? "multiselect"
                : obj[1].filterType === "date"
                ? "dates"
                : obj[1].filterType === "boolean"
                ? "checkbox"
                : obj[1].filterType === "balance"
                ? "balance"
                : FilterMatchMode.CONTAINS
            }
            return acc
          }, {})
          this.updateData(this.localData, this.localColumns)
        }
      }
    }
  },
  methods: {
    //TODO: This is stupid. Find a way to fix the actual bug.
    focusOnInput(input) {
      setTimeout(() => {
        this.$refs[input][0].$el.focus()
      }, 1)
    },
    onFilter(event) {
      this.filteredData = event.filteredValue
    },
    updateData(data, columns) {
      if (columns) {
        const dateFields = Object.entries(JSON.parse(JSON.stringify(columns)))
          .filter(v => v[1].filterType === "date")
          .map(v => v[0])

        data?.map(v => {
          dateFields.forEach(dateField => {
            v[dateField] = new Date(v[dateField]).toLocaleString("el-GR", { timeZone: "Europe/Athens" })
          })
        })
      }
      this.localData = data
    }
  },
  components: { Column, InputText, DataTable, MultiSelect, DateFromTo, TriStateCheckbox }
}
</script>

<style scoped lang="scss">
:deep(tr) {
  cursor: pointer;
}

:deep(.p-column-filter-row .p-column-filter-element) {
  width: auto;
}
</style>
