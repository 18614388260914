<template>
  <div class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex align-items-center justify-content-between">
          <div class="font-medium text-3xl text-900 mb-3">Στοιχεία Χρήστη</div>
          <div>
            <Button
              @click="create()"
              type="button"
              label="Δημιουργία"
              icon="pi pi-pencil"
              class="p-2 p-button-primary"
            />
          </div>
        </div>
        <Divider />
        <ul class="list-none p-0 m-0">
          <template v-for="(model, key) in userModel" :key="key">
            <li
              v-if="
                (!['readOnly', 'hidden'].includes(model.frontType) &&
                  key !== 'adminCapabilities' &&
                  key !== 'adminNetworks') ||
                userData['role'] === 'admin'
              "
              class="flex align-items-center p-2 flex-wrap"
            >
              <div class="text-500 md:w-6 sm:w-full font-medium">
                {{ model.niceName }}<span v-if="model.required" class="text-red-500">*</span>
              </div>
              <div class="text-900 md:w-6 sm:w-full md:flex-order-0 flex-order-1">
                <CustomModelInput
                  :invalid="!userData[key] && model.required"
                  v-model="userData[key]"
                  :type="model.frontType"
                  :options="model.options"
                />
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button"
import Divider from "primevue/divider"
import UserService from "@/services/User"
import { watch } from "vue"
import useModels from "@/states/models.js"
import CustomModelInput from "@/components/CustomModelInput"

export default {
  data() {
    return {
      userModel: null,
      userData: null,
      originalUserData: null
    }
  },
  created() {
    this.userService = new UserService()
    this.userData = {}
    this.originalUserData = {}

    const { models, resetModels } = useModels()
    resetModels()
    watch(
      models,
      models => {
        if (models.User && !this.userModel) {
          this.userModel = models.User

          delete this.userModel.password
          delete this.userModel.createdAt
          delete this.userModel.hasSetPassword

          this.userModel.role.options = this.userModel?.role?.options?.filter(v => v.value !== "superAdmin")
          for (const key in this.userModel) {
            if (this.userModel[key].default) {
              this.userData[key] = this.userModel[key].default
            }
          }

          this.originalUserData = { ...this.userData }
        }
      },
      { immediate: true }
    )
  },
  watch: {
    "userData.role": {
      handler: function (newRole) {
        if (newRole !== "admin") {
          this.userData["adminCapabilities"] = this.originalUserData["adminCapabilities"]
          this.userData["adminNetworks"] = this.originalUserData["adminNetworks"]
        }
      }
    }
  },
  methods: {
    async create() {
      if (
        Object.entries(this.userModel)
          .filter(v => v[1].required)
          .some(v => {
            return !this.userData[v[0]]
          })
      ) {
        this.$toast.add({
          severity: "warn",
          detail: `Παρακαλούμε συμπληρώστε όλα τα υποχρεωτικά πεδία.`,
          life: 3000
        })
      } else {
        const password = await this.userService.createUser(this.userData)
        if (password) {
          this.userData = { ...this.originalUserData }
          this.$toast.add({
            severity: "success",
            detail: `Ο χρήστης δημιουργήθηκε επιτυχώς. Ο κωδικός πρόσβασής του είναι ο ακόλουθος: ${password}`
          })
        }
      }
    }
  },
  components: {
    Button,
    Divider,
    CustomModelInput
  }
}
</script>
