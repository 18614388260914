/* eslint-disable */
import { createApp, reactive } from "vue"
import App from "@/App.vue"
import PrimeVue from "primevue/config"
import "@/assets/theme.scss"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.scss"
import Card from "primevue/card"
import Button from "primevue/button"
import router from "@/router"
import axios from "axios"
import { createAuth } from "@websanova/vue-auth"
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js"
import driverAuthBearer from "@websanova/vue-auth/dist/drivers/auth/bearer.common.js"
import routerVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js"
import Tooltip from "primevue/tooltip"
import VueExcelXlsx from "vue-excel-xlsx"
import ToastService from "primevue/toastservice"
import { createI18n } from "vue-i18n"

const auth = createAuth({
  plugins: {
    http: axios,
    router: router
  },
  drivers: {
    http: driverHttpAxios,
    auth: driverAuthBearer,
    router: routerVueRouter
  },
  options: {
    rolesKey: "role",
    notFoundRedirect: { path: "/" },
    forbiddenRedirect: { path: "/" },
    loginData: { fetchUser: false },
    logoutData: { redirect: "/login" }
  }
})

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = event => {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value()
      }
    }
    document.addEventListener("click", el.clickOutsideEvent)
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent)
  }
}

const i18n = createI18n({
  locale: "en-US",
  numberFormats: {
    "en-US": {
      currency: {
        style: "currency",
        currency: "EUR"
      }
    }
  },
  global: true
})

const app = createApp(App)
app
  .use(PrimeVue)
  .use(ToastService)
  .use(auth)
  .use(router)
  .component("Card", Card)
  .component("Button", Button)
  .directive("tooltip", Tooltip)
  .use(VueExcelXlsx)
  .use(i18n)
  .directive("click-outside", clickOutside)
  .mount("#app")
