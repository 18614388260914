<template>
  <div class="surface-card shadow-2 p-3 border-round">
    <div class="mb-3">
      <div class="font-medium text-3xl text-900 mb-3">Υπόλοιπο</div>
      <div
        v-show="balance !== null"
        class="icon flex align-items-center justify-content-center border-round balance-level-indicator"
        :style="{ backgroundColor: balanceLevelIndicatorColor }"
      >
        <i class="pi pi-euro text-white text-xl"></i>
      </div>
      <Divider />
      <div class="flex justify-content-between align-content-center">
        <div v-show="balance !== null" class="text-900 font-medium text-4xl">
          {{ balance !== null && this.$i18n.n(balance, "currency") }}
        </div>
        <div v-show="balance !== null" class="text-500 text-m align-self-center">
          ({{ transactionsLength }} {{ transactionsLength === 1 ? "συναλλαγή" : "συναλλαγές" }})
        </div>
      </div>
      <Divider />
      <div class="flex justify-content-evenly">
        <InputNumber
          :disabled="this.$auth.user().status === 'locked'"
          v-if="paymentMethods.length > 0 || $auth.check(['manualTransactions', 'superAdmin'])"
          placeholder="Ποσό"
          v-model="amount"
          mode="currency"
          currency="EUR"
        />
        <div class="flex justify-content-center">
          <div
            v-if="paymentMethods.includes('cc') && $auth.user()?._id === userId"
            class="flex flex-1 flex-column text-center justify-content-center cursor-pointer"
            :class="{ 'p-disabled': this.$auth.user().status === 'locked' }"
            @click="openCCTransactionModal()"
          >
            <img class="w-6 m-auto" src="@/assets/cc.png" />
            <div class="mt-1">Κάρτα</div>
          </div>
          <div
            v-if="paymentMethods.includes('iris') && $auth.user()?._id === userId"
            class="flex flex-1 flex-column text-center justify-content-center cursor-pointer"
            :class="{ 'p-disabled': this.$auth.user().status === 'locked' }"
            @click="pay('iris')"
          >
            <img class="w-6 m-auto" src="@/assets/iris.jpg" />
            <div class="mt-1">IRIS Payments</div>
          </div>
          <div
            v-if="paymentMethods.includes('dias') && $auth.user()?._id === userId"
            class="flex flex-1 flex-column text-center justify-content-center cursor-pointer"
            :class="{ 'p-disabled': this.$auth.user().status === 'locked' }"
            @click="pay('dias')"
          >
            <img class="w-6 m-auto" src="@/assets/dias.png" />
            <div class="mt-1">ΔΙΑΣ</div>
          </div>
          <div
            v-if="$auth.check(['manualTransactions', 'superAdmin'])"
            class="flex flex-1 flex-column text-center justify-content-center cursor-pointer"
            :class="{ 'p-disabled': this.$auth.user().status === 'locked' }"
            @click="openManualTransactionConfirmation()"
          >
            <div>Χειροκίνητη Συναλλαγή</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form :action="formAction" method="POST" ref="form">
    <input type="hidden" v-for="item in formData" :key="item.name" :name="item.name" :value="item.value" />
  </form>
  <Dialog
    :modal="true"
    header="Πληρωμή μέσω κάρτας"
    v-model:visible="displayCCTransactionModal"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '60vw' }"
  >
    <div class="confirmation-content">
      <div class="flex align-content-center">
        <i class="pi pi-exclamation-triangle mr-3 align-self-center" style="font-size: 2rem" />
        <div class="align-self-center">
          Παρακαλούμε συμπληρώστε τα παρακάτω πεδία και πατήστε "Συνέχεια", για να μεταφερθείτε στο περιβάλλον
          της τράπεζας.
        </div>
      </div>
      <div class="grid mt-5">
        <div class="col-6">
          <label for="username" class="block text-900 font-medium mb-2">Χώρα</label>
          <InputText class="w-full" readonly v-model="billingDetails.billCountry" placeholder="GR" />
        </div>
        <div class="col-6">
          <label for="username" class="block text-900 font-medium mb-2">Νομός</label>
          <InputText class="w-full" v-model="billingDetails.billState" placeholder="Αττική" />
        </div>
        <div class="col-6">
          <label for="username" class="block text-900 font-medium mb-2">Πόλη</label>
          <InputText class="w-full" v-model="billingDetails.billCity" placeholder="Μαρούσι" />
        </div>
        <div class="col-6">
          <label for="username" class="block text-900 font-medium mb-2">Ταχ. Κώδικας</label>
          <InputText class="w-full" v-model="billingDetails.billZip" placeholder="15125" />
        </div>
        <div class="col-12">
          <label for="username" class="block text-900 font-medium mb-2">Διεύθυνση</label>
          <InputText class="w-full" v-model="billingDetails.billAddress" placeholder="Φραγκοκλησσιάς 45" />
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="Ακύρωση"
        icon="pi pi-times"
        @click="
          () => {
            this.billingDetails = this.initializeBillingDetails()
            this.displayCCTransactionModal = false
          }
        "
        class="p-button-text"
      />
      <Button label="Συνέχεια" icon="pi pi-check" @click="pay('cc')" class="p-button-text" autofocus />
    </template>
  </Dialog>
  <Dialog
    :modal="true"
    header="Επιβεβαίωση Χειροκίνητης Συναλλαγής"
    v-model:visible="displayManualTransactionConfirmation"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '60vw' }"
  >
    <div class="confirmation-content">
      <div class="flex align-content-center">
        <i class="pi pi-exclamation-triangle mr-3 align-self-center" style="font-size: 2rem" />
        <div v-if="transactionType" class="align-self-center">
          Είστε σίγουροι ότι επιθυμείτε να
          <span v-if="['charge', 'credit'].includes(transactionType)"
            ><span v-if="transactionType === 'charge'">χρεώσετε</span
            ><span v-if="transactionType === 'credit'">πιστώσετε</span> το πορτοφόλι του χρήστη</span
          >
          <span v-else
            >προχωρήσετε με τη συναλλαγή
            {{ filteredTransactionTypes.find(v => v.value === transactionType).name }}
          </span>
          με {{ this.$i18n.n(amount, "currency") }};
        </div>
        <div v-else class="align-self-center">Παρακαλούμε επιλέξτε είδος συναλλαγής.</div>
      </div>
      <div class="grid gap-1 flex-nowrap mt-5">
        <Dropdown
          :modelValue="transactionType"
          :options="filteredTransactionTypes"
          class="col-4 p-0"
          optionLabel="name"
          optionValue="value"
          placeholder="Είδος συναλλαγής"
          @update:modelValue="
            value => {
              this.transactionType = value
            }
          "
          :showClear="true"
        />
        <InputText class="col-8" v-model="transactionComment" placeholder="Σχόλιο" />
      </div>
    </div>
    <template #footer>
      <Button
        label="Όχι"
        icon="pi pi-times"
        @click="
          () => {
            this.displayManualTransactionConfirmation = false
          }
        "
        class="p-button-text"
      />
      <Button label="Ναι" icon="pi pi-check" @click="manualTransaction" class="p-button-text" autofocus />
    </template>
  </Dialog>
</template>

<script>
import Divider from "primevue/divider"
import InputNumber from "primevue/inputnumber"
import Dialog from "primevue/dialog"
import InputText from "primevue/inputtext"
import Dropdown from "primevue/dropdown"
import PaymentService from "@/services/Payment"
import TransactionService from "@/services/Transaction"
import useSettings from "@/states/settings"
import { watch } from "vue"
import useModels from "@/states/models"

export default {
  props: ["balance", "transactions", "paymentMethods", "userId"],
  emits: ["manualTransactionCompleted"],
  data() {
    return {
      transactionsLength: 0,
      amount: null,
      toast: null,
      displayManualTransactionConfirmation: false,
      transactionComment: "",
      transactionType: null,
      transactionTypes: null,
      filteredTransactionTypes: null,
      formData: null,
      formAction: null,
      displayCCTransactionModal: false,
      billingDetails: this.initializeBillingDetails(),
      settings: null
    }
  },
  created() {
    this.paymentService = new PaymentService()
    this.transactionService = new TransactionService()
  },
  updated() {
    this.transactionsLength = this.transactions?.length
  },
  watch: {
    displayManualTransactionConfirmation: {
      handler(displayManualTransactionConfirmation) {
        if (!displayManualTransactionConfirmation) {
          this.amount = null
          this.transactionType = null
        }
      }
    },
    amount: {
      handler(amount) {
        if (amount < 0) {
          this.filteredTransactionTypes = this.transactionTypes.filter(v => v.value !== "credit")
        } else {
          this.filteredTransactionTypes = this.transactionTypes.filter(v => v.value !== "charge")
        }
      }
    }
  },
  mounted() {
    const { models, resetModels } = useModels()
    resetModels()
    watch(
      models,
      models => {
        if (models.Transaction && !this.transactionModel) {
          this.transactionModel = models.Transaction
          this.transactionTypes = this.transactionModel.type.options
        }
      },
      { immediate: true }
    )

    const { settings } = useSettings()
    watch(
      settings,
      settings => {
        if (Object.keys(settings).length > 0) {
          this.settings = settings
        }
      },
      { immediate: true }
    )
  },
  computed: {
    balanceLevelIndicatorColor: function () {
      let color = ""
      if (this.settings && this.$props.balance !== null) {
        this.settings?.balanceLevels?.forEach(balanceLevel => {
          const upperLimit =
            balanceLevel["Έως"] !== undefined ? parseFloat(balanceLevel["Έως"]) : Number.MAX_VALUE
          if (
            !color &&
            (balanceLevel["Από"] === null || this.$props.balance >= parseFloat(balanceLevel["Από"])) &&
            this.$props.balance <= upperLimit
          ) {
            color = `#${balanceLevel["Χρώμα"]}`
          }
        })
      }
      return color
    }
  },
  methods: {
    async pay(paymentMethod) {
      let response

      switch (paymentMethod) {
        case "cc":
        case "iris":
          if (this.validateInput(paymentMethod === "cc")) {
            response = await this.paymentService.pay(paymentMethod, this.amount, this.billingDetails)
            if (response?.data?.success) {
              this.formData = response.data.data?.formData
              this.formAction = response.data.data?.formAction
              this.$nextTick(() => {
                this.$root.apiCallInProgress = true
                this.$refs.form.submit()
              })
            }
          }
          break
        case "dias":
          response = await this.paymentService.pay(paymentMethod)
          if (response?.data?.success) {
            this.$toast.add({
              severity: "success",
              detail: `Μπορείτε να χρησιμοποιήσετε τον ακόλουθο κωδικό πληρωμής, για να πραγματοποιήσετε τη συναλλαγή σας: ${response.data.data?.rf}`
            })
          }
          break
        default:
      }
    },
    openManualTransactionConfirmation() {
      if (this.validateInput(false, false, false)) {
        this.displayManualTransactionConfirmation = true
      }
    },
    openCCTransactionModal() {
      if (this.validateInput()) {
        this.displayCCTransactionModal = true
      }
    },
    async manualTransaction() {
      if (this.validateInput(false, true, false)) {
        const success = await this.transactionService.createTransaction({
          amount: this.amount,
          type: this.transactionType,
          comment: this.transactionComment,
          user: this.userId
        })

        if (success) {
          this.$toast.add({
            severity: "success",
            detail: "Η συναλλαγή δημιουργήθηκε επιτυχώς.",
            group: "noClick"
          })
          this.displayManualTransactionConfirmation = false
          this.amount = null
          this.transactionComment = null
          this.transactionType = null
          this.$emit("manualTransactionCompleted")
        }
      }
    },
    validateInput(withBilling = false, withType = false, onlyPositiveAmount = true) {
      if (!this.amount || (this.amount < 0 && onlyPositiveAmount)) {
        this.$toast.add({
          severity: "warn",
          detail: "Παρακαλούμε συμπληρώστε έγκυρο ποσό συναλλαγής.",
          life: 3000
        })
        return false
      }

      if (withBilling && Object.values(this.billingDetails).some(v => v === "")) {
        this.$toast.add({
          severity: "warn",
          detail: "Παρακαλούμε συμπληρώστε όλα τα επιπλέον πεδία.",
          life: 3000
        })
        return false
      }

      if (withType && !this.transactionType) {
        this.$toast.add({
          severity: "warn",
          detail: "Παρακαλούμε επιλέξτε είδος συναλλαγής.",
          life: 3000
        })
        return false
      }

      return true
    },
    initializeBillingDetails: function () {
      return { billCountry: "GR", billState: "", billCity: "", billZip: "", billAddress: "" }
    }
  },
  components: { Divider, InputNumber, Dialog, InputText, Dropdown }
}
</script>

<style scoped lang="scss">
.surface-card {
  position: relative;
}

.balance-level-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 2.5rem;
  height: 2.5rem;
}
</style>
