<template>
  <div v-if="transactionData && transactionModel" class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex align-items-center justify-content-between">
          <div class="font-medium text-3xl text-900 mb-3">Στοιχεία Συναλλαγής</div>
          <div>
            <Button
              v-if="$auth.check(['reverseTransactions', 'superAdmin'])"
              @click="confirmationDialog = 'reverse'"
              type="button"
              label="Αντιλογισμός"
              icon="pi pi-pencil"
              class="p-2 p-button-secondary mr-2"
              :class="{ 'p-disabled': this.$auth.user().status === 'locked' }"
            />
            <Button
              v-if="$auth.check(['cancelTransactions', 'superAdmin'])"
              @click="confirmationDialog = 'cancel'"
              type="button"
              label="Ακύρωση"
              icon="pi pi-pencil"
              class="p-2 p-button-danger"
              :class="{ 'p-disabled': this.$auth.user().status === 'locked' }"
            />
          </div>
        </div>
        <Divider />
        <ul class="list-none p-0 m-0">
          <li
            v-for="(model, key) in transactionModel"
            :key="key"
            class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap"
          >
            <div class="text-500 md:w-4 sm:w-full font-medium">{{ model.niceName }}</div>
            <div class="text-900 md:w-8 sm:w-full md:flex-order-0 flex-order-1">
              <router-link
                v-if="['user'].includes(key)"
                target="_blank"
                class="no-underline cursor-pointer"
                :to="`/user/${transactionData[key]?._id}`"
                >{{ transactionData[key]?.username }}</router-link
              >

              <CustomModelValue v-if="!['user'].includes(key)" :model="model" :value="transactionData[key]" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <Dialog
    :modal="true"
    :header="`Επιβεβαίωση ${this.confirmationDialog === 'cancel' ? 'Ακύρωσης' : 'Αντιλογισμού'} Συναλλαγής`"
    v-model:visible="displayConfirmationDialog"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '60vw' }"
  >
    <div class="confirmation-content">
      <div class="flex align-content-center">
        <i class="pi pi-exclamation-triangle mr-3 align-self-center" style="font-size: 2rem" />
        <div class="align-self-center">
          Είστε σίγουροι ότι επιθυμείτε να <span v-if="confirmationDialog === 'cancel'">ακυρώσετε</span
          ><span v-if="confirmationDialog === 'reverse'">αντιλογίσετε</span> τη συγκεκριμένη συναλλαγή;
        </div>
      </div>
    </div>
    <template #footer>
      <Button label="Όχι" icon="pi pi-times" @click="confirmationDialog = null" class="p-button-text" />
      <Button
        label="Ναι"
        icon="pi pi-check"
        @click="confirmationDialog === 'cancel' ? cancel() : reverse()"
        class="p-button-text"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import Button from "primevue/button"
import Divider from "primevue/divider"
import TransactionService from "@/services/Transaction"
import { watch } from "vue"
import useModels from "@/states/models.js"
import CustomModelValue from "@/components/CustomModelValue"
import Dialog from "primevue/dialog"

export default {
  data() {
    return {
      transactionModel: null,
      transactionData: null,
      confirmationDialog: null,
      displayConfirmationDialog: false
    }
  },
  created() {
    this.transactionService = new TransactionService()

    const { models, resetModels } = useModels()
    resetModels()
    watch(
      models,
      models => {
        if (!this.transactionModel && models.Transaction) {
          this.transactionModel = models.Transaction
        }
      },
      { immediate: true }
    )

    if (undefined !== this.$route.query.transactionSucceeded) {
      this.$toast.add({
        severity: "success",
        detail: "Η πληρωμή ολοκληρώθηκε επιτυχώς."
      })

      // eslint-disable-next-line no-unused-vars
      const { transactionSucceeded, ...newQuery } = this.$route.query
      this.$router.replace({ newQuery })
    }
  },
  computed: {
    paymentMethods() {
      return this.userData.paymentMethods || []
    }
  },
  watch: {
    confirmationDialog: {
      handler(value) {
        this.displayConfirmationDialog = value !== null
      }
    }
  },
  async mounted() {
    const data = await this.transactionService.getTransaction(this.$route.params._id)
    if (data) {
      data.amount = this.$i18n.n(data.amount, "currency")
      this.transactionData = data
    }
  },
  methods: {
    async cancel() {
      const success = await this.transactionService.cancelTransaction(this.transactionData._id)
      if (success) {
        this.$toast.add({
          severity: "success",
          detail: "Η συναλλαγή ακυρώθηκε.",
          group: "noClick"
        })
      }
      this.confirmationDialog = null
    },
    async reverse() {
      const success = await this.transactionService.reverseTrsansaction(this.transactionData._id)
      if (success) {
        this.$toast.add({
          severity: "success",
          detail: "Η συναλλαγή αντιλογίστηκε.",
          group: "noClick"
        })
      }
      this.confirmationDialog = null
    }
  },
  components: {
    Button,
    Divider,
    CustomModelValue,
    Dialog
  }
}
</script>

<style scoped lang="scss">
.p-column-filter-row .p-column-filter-element {
  width: auto;
}
</style>
