import { createWebHistory, createRouter } from "vue-router"
import Home from "@/views/Home"
import Users from "@/views/Admin/Users"
import User from "@/views/User"
import CreateUser from "@/views/Admin/CreateUser"
import Transactions from "@/views/Transactions"
import Transaction from "@/views/Transaction"
import Settings from "@/views/Admin/Settings"
import Login from "@/views/Login"
import NotFound from "@/views/NotFound"
import ResetPassword from "@/views/ResetPassword"
import Profile from "@/views/Profile"
import Balance from "@/views/Balance"

export const routes = [
  {
    name: "Αρχική",
    path: "/",
    component: Home,
    meta: { auth: true, showInNav: false }
  },
  {
    name: "Υπόλοιπο",
    path: "/balance",
    component: Balance,
    meta: { auth: true, showInNav: ["admin", "user"] }
  },
  {
    name: "Σύνδεση",
    path: "/login",
    component: Login,
    meta: { auth: false, showInNav: false }
  },
  {
    name: "Επαναφορά κωδικού",
    path: "/reset_password/:resetToken?",
    component: ResetPassword,
    meta: { showInNav: false }
  },
  {
    name: "Χρήστες",
    path: "/admin/users",
    component: Users,
    meta: {
      auth: {
        roles: ["admin", "superAdmin"]
      },
      showInNav: "auth"
    }
  },
  {
    name: "Δημιουργία χρήστη",
    path: "/admin/create_user",
    component: CreateUser,
    meta: {
      auth: {
        roles: ["superAdmin", "manageUsers"]
      },
      showInNav: "auth"
    }
  },
  {
    name: "Χρήστης",
    path: "/user/:_id",
    meta: {
      auth: true
    },
    component: User
  },
  {
    name: "Συναλλαγή",
    path: "/transaction/:_id",
    meta: {
      auth: true
    },
    component: Transaction
  },
  {
    name: "Συναλλαγές",
    path: "/transactions",
    component: Transactions,
    meta: {
      auth: true,
      showInNav: true
    }
  },
  {
    name: "Ρυθμίσεις",
    path: "/admin/settings",
    component: Settings,
    meta: {
      showInNav: "auth",
      auth: {
        roles: ["superAdmin"]
      }
    }
  },
  {
    name: "Προφίλ",
    path: "/profile",
    component: Profile,
    meta: { auth: true, showInNav: true }
  },
  { path: "/:pathMatch(.*)*", component: NotFound }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
