import { ref } from "vue"

const state = ref({})
const initialState = ref({})

export default function useModels() {
  return {
    models: state,
    resetModels: () => {
      state.value = JSON.parse(JSON.stringify(initialState.value))
    },
    setModels: models => {
      state.value = models
      initialState.value = JSON.parse(JSON.stringify(models))
    }
  }
}
