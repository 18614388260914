import axios from "axios"

export default class UserService {
  async getUsers() {
    const response = await axios.get("/users")
    return response?.data?.success && response.data.data
  }
  async getUser(userId) {
    const response = await axios.get(`/user/${userId !== undefined ? userId : ""}`)
    return response?.data?.success && response.data.data
  }
  async updateUser(user) {
    const userId = user._id
    const response = await axios.post(`/user/${userId !== undefined ? userId : ""}`, user)
    return response?.data?.success
  }
  async createUser(user) {
    const response = await axios.post(`/user`, user)
    return response?.data?.success && response.data.data.password
  }
}
