<template>
  <div>
    <InputText
      class="custom-model-input w-full"
      :class="{
        'p-invalid': invalid
      }"
      v-if="['text', 'readOnly'].includes(type)"
      :disabled="type === 'readOnly' || disabled"
      :value="modelValue === false ? 'Όχι' : modelValue === true ? 'Ναι' : modelValue"
      @input="event => updateValue(event.target.value)"
    />
    <Password
      class="custom-model-input w-full"
      :class="{
        'p-invalid': invalid
      }"
      v-if="type === 'password'"
      :modelValue="modelValue"
      @update:modelValue="updateValue"
      strongRegex="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
      promptLabel=" "
      weakLabel="Μη αποδεκτός κωδικός"
      mediumLabel="Μη αποδεκτός κωδικός"
      strongLabel="Αποδεκτός κωδικός"
    >
      <template #footer>
        <Divider />
        <p class="mt-4">Κριτήρια</p>
        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
          <li>Τουλάχιστον 8 χαρακτήρες</li>
          <li>Τουλάχιστον ένα μικρό γράμμα</li>
          <li>Τουλάχιστον ένα κεφαλαίο γράμμα</li>
          <li>Τουλάχιστον έναν αριθμό</li>
          <li>Τουλάχιστον ένα σύμβολο</li>
        </ul>
      </template>
    </Password>

    <InputNumber
      class="custom-model-input w-full"
      :class="{
        'p-invalid': invalid
      }"
      v-if="type === 'number'"
      :disabled="disabled"
      :value="modelValue"
      @input="event => updateValue(event.value)"
    />
    <Dropdown
      class="custom-model-input w-full"
      :class="{
        'p-invalid': invalid
      }"
      v-if="type === 'dropdown'"
      :disabled="disabled"
      :modelValue="modelValue"
      :optionLabel="typeof options?.[0] === 'object' && options?.[0] !== null ? 'name' : undefined"
      :optionValue="typeof options?.[0] === 'object' && options?.[0] !== null ? 'value' : undefined"
      :options="options"
      @change="
        option => {
          updateValue(option.value)
        }
      "
      :showClear="true"
    />
    <MultiSelect
      class="custom-model-input w-full"
      :class="{
        'p-invalid': invalid
      }"
      v-if="type === 'multiselect'"
      :disabled="disabled"
      :modelValue="modelValue"
      :optionLabel="typeof options?.[0] === 'object' && options?.[0] !== null ? 'name' : undefined"
      :optionValue="typeof options?.[0] === 'object' && options?.[0] !== null ? 'value' : undefined"
      :options="options"
      @change="
        option => {
          updateValue(option.value)
        }
      "
    />
    <div v-if="type === 'checkboxes'">
      <div v-for="option in options" :key="option.name" class="flex gap-2 mt-1">
        <Checkbox
          :value="option.value"
          :disabled="disabled"
          :trueValue="checkboxesValues?.includes(option.value)"
          v-model="checkboxesValues"
          @change="
            () => {
              updateValue(checkboxesValues)
            }
          "
        />
        <div>{{ option.name }}</div>
      </div>
    </div>
    <div v-if="type === 'list'" class="grid m-2">
      <div class="col-8 flex gap-2 flex-column py-0">
        <div
          v-for="(singleValue, index) in modelValue"
          :key="index"
          class="flex gap-4 p-2 single-value justify-content-between surface-border border-1"
        >
          <div v-if="properties" class="flex flex-wrap gap-2 align-items-center">
            <div v-for="property in properties" :key="property" class="flex align-items-center gap-2">
              <div>{{ property.name }}:</div>
              <InputText
                v-if="property.frontType === 'text'"
                class="custom-model-input"
                :class="{
                  'p-invalid': invalid
                }"
                :value="singleValue[property.name]"
                @input="event => updateListValue(index, property.name, event.target.value)"
              />
              <InputNumber
                v-if="property.frontType === 'number'"
                class="custom-model-input"
                :class="{
                  'p-invalid': invalid
                }"
                :value="singleValue[property.name]"
                @input="event => updateListValue(index, property.name, event.value)"
              />
              <ColorPicker
                v-if="property.frontType === 'color'"
                :modelValue="singleValue[property.name]"
                @update:modelValue="value => updateListValue(index, property.name, value)"
              />
            </div>
          </div>
          <div v-else-if="options" class="flex flex-wrap gap-2 align-items-center">
            <Dropdown
              class="custom-model-input w-full"
              :class="{
                'p-invalid': invalid
              }"
              :modelValue="singleValue"
              :optionLabel="typeof options?.[0] === 'object' && options?.[0] !== null ? 'name' : undefined"
              :optionValue="typeof options?.[0] === 'object' && options?.[0] !== null ? 'value' : undefined"
              :options="options"
              @change="
                option => {
                  updateListValue(index, null, option.value)
                }
              "
              :showClear="true"
            />
          </div>
          <div v-else>
            <InputText
              class="custom-model-input"
              :class="{
                'p-invalid': invalid
              }"
              :value="singleValue"
              @input="event => updateListValue(index, null, event.target.value)"
            />
          </div>
          <Button
            @click="deleteListValue(index)"
            type="button"
            icon="pi pi-trash"
            class="p-2 p-button-danger align-self-center w-auto"
          />
        </div>
      </div>
      <Button
        @click="addListValue(properties)"
        type="button"
        label="Προσθήκη"
        icon="pi pi-plus"
        class="p-2 p-button-secondary align-self-center col-4"
      />
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext"
import InputNumber from "primevue/inputnumber"
import Dropdown from "primevue/dropdown"
import Checkbox from "primevue/checkbox"
import ColorPicker from "primevue/colorpicker"
import Password from "primevue/password"
import Divider from "primevue/divider"
import MultiSelect from "primevue/multiselect"

export default {
  props: ["type", "modelValue", "options", "properties", "disabled", "invalid"],
  data() {
    return {
      checkboxesValues: this.$props.modelValue
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("update:modelValue", value)
    },
    addListValue(properties) {
      this.updateValue((this.$props.modelValue || []).concat(properties ? {} : ""))
    },
    deleteListValue(index) {
      this.updateValue(this.$props.modelValue.filter((v, idx) => idx !== index))
    },
    updateListValue(index, property, value) {
      this.updateValue(
        [...this.$props.modelValue].map((v, idx) => {
          if (idx === index) {
            if (property) {
              return { ...v, [property]: value }
            } else {
              return value
            }
          }
          return v
        })
      )
    }
  },
  components: { InputText, InputNumber, Dropdown, Checkbox, ColorPicker, Password, Divider, MultiSelect }
}
</script>

<style scoped lang="scss">
.custom-model-input,
.custom-model-input:enabled:focus:focus-visible,
:deep(.custom-model-input .p-inputtext),
:deep(.custom-model-input:enabled:focus:focus-visible .p-inputtext) {
  padding: 0;
  border: none;
  box-shadow: none !important;
  border-radius: 0 !important;
  outline: none !important;
}

.custom-model-input,
.custom-model-input:enabled:focus:focus-visible,
/*:deep(.custom-model-input .p-inputtext:not(.p-dropdown-label)),
:deep(.custom-model-input:enabled:focus:focus-visible .p-inputtext:not(.p-dropdown-label))*/ {
  border-bottom: 1px solid #d4d4d8;
}

.grid .single-value {
  border-radius: 0.5rem;
}
</style>
