import axios from "axios"

export default class TransactionService {
  async getTransactions() {
    const response = await axios.get(`/transactions`)
    return response?.data?.success && response.data.data
  }

  async getTransaction(transactionId) {
    const response = await axios.get(`/transaction/${transactionId}`)
    return response?.data?.success && response.data.data
  }

  async createTransaction(transaction) {
    const response = await axios.post("/transaction", transaction)
    return response?.data?.success
  }

  async reverseTrsansaction(transactionId) {
    const response = await axios.post(`/reverse_transaction/${transactionId}`)
    return response?.data?.success
  }

  async cancelTransaction(transactionId) {
    const response = await axios.post(`/cancel_transaction/${transactionId}`)
    return response?.data?.success
  }
}
