<template>
  <div class="h-auto px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div class="text-center mb-5">
        <img src="@/assets/logo.png" alt="Image" height="50" class="mb-3" />
      </div>

      <div>
        <label for="username" class="block text-900 font-medium mb-2">Όνομα χρήστη</label>
        <InputText
          v-on:keyup.enter="login"
          v-model="username"
          id="username"
          type="text"
          class="w-full mb-3"
        />

        <label for="password" class="block text-900 font-medium mb-2">Κωδικός σύνδεσης</label>
        <InputText
          v-on:keyup.enter="login"
          v-model="password"
          id="password"
          type="password"
          class="w-full mb-3"
        />

        <div class="flex align-items-center justify-content-between mb-6">
          <div class="flex flex-column gap-2">
            <!--<div class="flex align-items-center">
              <Checkbox id="rememberme" :binary="true" v-model="rememberMe" class="mr-2"></Checkbox>
              <label for="rememberme">Να με θυμάσαι</label>
            </div>-->
            <div class="flex align-items-center">
              <Checkbox id="isAdmin" :binary="true" v-model="isAdmin" class="mr-2"></Checkbox>
              <label for="isAdmin">Διαχειριστής</label>
            </div>
          </div>

          <a
            class="font-medium no-underline ml-2 text-right cursor-pointer"
            @click="this.displayResetPasswordDialog = true"
            >Επαναφορά κωδικού</a
          >
        </div>

        <Button label="Σύνδεση" icon="pi pi-user" class="w-full" @click="login"></Button>
      </div>
    </div>
  </div>
  <Dialog
    :modal="true"
    header="Επαναφορά κωδικού"
    v-model:visible="displayResetPasswordDialog"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '60vw' }"
  >
    <div class="confirmation-content">
      <div class="flex align-content-center">
        <div class="align-self-center">
          Παρακαλούμε συμπληρώστε το e-mail σας στο ακόλουθο πεδίο και πατήστε το κουμπί "Επαναφορά".
        </div>
      </div>
      <InputText class="w-full mt-3" v-model="email" placeholder="Email" />
    </div>
    <template #footer>
      <Button
        label="Ακύρωση"
        icon="pi pi-times"
        @click="
          () => {
            this.displayResetPasswordDialog = false
            this.email = null
          }
        "
        class="p-button-text"
      />
      <Button label="Επαναφορά" icon="pi pi-check" @click="forgotPassword" class="p-button-text" autofocus />
    </template>
  </Dialog>
</template>

<script>
import InputText from "primevue/inputtext"
import Checkbox from "primevue/checkbox"
import Dialog from "primevue/dialog"
import ResetPasswordService from "@/services/ResetPassword"

export default {
  data() {
    return {
      username: null,
      password: null,
      rememberMe: false,
      isAdmin: false,
      redirectedFrom: this.$auth.redirect(),
      displayResetPasswordDialog: false,
      email: null
    }
  },
  created() {
    this.resetPasswordService = new ResetPasswordService()
  },
  methods: {
    login() {
      const loginOptions = {
        data: { username: this.username, password: this.password },
        redirect: {
          path: this.redirectedFrom?.from?.path || "/"
        }
      }
      if (this.isAdmin) {
        loginOptions.url = "/auth/admin_login"
      }

      this.$auth.login(loginOptions).then(null, res => {
        res?.response?.data?.error?.details &&
          this.$toast.add({
            severity: "error",
            detail: res.response?.data?.error.details,
            life: 3000,
            group: "noClick"
          })
      })
    },
    async forgotPassword() {
      if (!this.email) {
        this.$toast.add({
          severity: "warn",
          detail: "Παρακαλούμε συμπληρώστε το email σας."
        })
      } else {
        const success = await this.resetPasswordService.forgotPassword(this.email)

        if (success) {
          this.$toast.add({
            severity: "success",
            detail: "Εφόσον το email σας ανήκει σε έγκυρο χρήστη, θα λάβετε μήνυμα με περαιτέρω οδηγίες.",
            group: "noClick"
          })
          this.displayResetPasswordDialog = false
          this.email = null
        }
      }
    }
  },
  components: { InputText, Checkbox, Dialog }
}
</script>
