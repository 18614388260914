<template><User noProfile="true" noTransactions="true" /></template>

<script>
import User from "@/views/User"
import router from "@/router"

export default {
  components: { User },
  created() {
    if (this.$auth.check("superAdmin")) {
      router.push("/admin/users")
    }
  }
}
</script>
