import axios from "axios"

export default class ResetPasswordService {
  async forgotPassword(email) {
    const response = await axios.post("/auth/forgot_password", { email })
    return response?.data?.success
  }

  async resetPassword(resetToken, password) {
    const response = await axios.post("/auth/reset_password", { resetToken, password })
    return response?.data?.success
  }
}
