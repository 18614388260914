<script>
import router from "@/router"

export default {
  created() {
    if (this.$auth.check("superAdmin")) {
      router.push("/admin/users")
    } else {
      router.push("/balance")
    }
  }
}
</script>
