import axios from "axios"

export default class SettingsService {
  async getSettings(settings) {
    const response = await axios.get("/settings", settings)
    return response?.data?.success && response.data.data
  }
  async setSettings(settings) {
    const response = await axios.post("/settings", settings)
    return response?.data?.success
  }
}
