import { ref } from "vue"

const state = ref({})

export default function useSettings() {
  return {
    settings: state,
    setSettings: settings => {
      state.value = settings
    }
  }
}
