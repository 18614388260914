<template>
  <div class="flex justify-content-between align-items-center mb-5">
    <router-link class="w-2" to="/"><img src="@/assets/logo.png" /></router-link>
    <Menubar
      :model="[
        ...items
          .filter(v => v.meta?.showInNav)
          .map(v => ({
            label: v.name,
            command: () => {
              this.$router.push(v.path)
            },
            items: v.children
              ?.filter(v2 => v2.meta?.showInNav)
              .map(v2 => ({
                label: v2.name,
                command: () => {
                  this.$router.push(v2.path)
                }
              }))
          })),
        this.showLogout && {
          label: 'Αποσύνδεση',
          command: () => {
            this.displayConfirmationDialog = true
          }
        }
      ]"
    />
    <Dialog
      :modal="true"
      header="Επιβεβαίωση αποσύνδεσης"
      v-model:visible="displayConfirmationDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '60vw' }"
    >
      <div class="confirmation-content">
        <div class="flex align-content-center">
          <i class="pi pi-exclamation-triangle mr-3 align-self-center" style="font-size: 2rem" />
          <div class="align-self-center">Είστε σίγουροι ότι επιθυμείτε να αποσυνδεθείτε;</div>
        </div>
      </div>
      <template #footer>
        <Button
          label="Όχι"
          icon="pi pi-times"
          @click="displayConfirmationDialog = false"
          class="p-button-text"
        />
        <Button label="Ναι" icon="pi pi-check" @click="this.$auth.logout()" class="p-button-text" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
#nav {
  text-align: center;
}
</style>

<script>
import Menubar from "primevue/menubar"
import Dialog from "primevue/dialog"
import { routes } from "@/router.js"

export default {
  data() {
    return {
      showLogout: false,
      items: [],
      displayConfirmationDialog: false
    }
  },
  watch: {
    "$auth.$vm.state": {
      deep: true,
      immediate: true,
      async handler() {
        if (this.$auth.$vm.state.authenticated) {
          this.showLogout = true
        } else {
          this.showLogout = false
        }

        if (this.$auth?.$vm?.state?.data?.hasSetPassword) {
          this.items = JSON.parse(JSON.stringify(routes)).map(v => {
            if (v.meta?.showInNav === "auth") {
              v.meta.showInNav = this.$auth.user()?.role?.some(v2 => v.meta.auth.roles.includes(v2))
            } else if (Array.isArray(v.meta?.showInNav)) {
              v.meta.showInNav = this.$auth.user()?.role?.some(v2 => v.meta?.showInNav?.includes(v2))
            }
            return v
          })
        }
      }
    }
  },
  components: { Menubar, Dialog }
}
</script>

<style scoped lang="scss">
.p-menubar {
  padding: 0;
}
</style>
