<template>
  <DataTableWithFilters
    :v-if="transactions"
    :data="transactions"
    :columns="columns"
    :rowClick="goToTransaction"
  ></DataTableWithFilters>
</template>

<script>
import TransactionService from "@/services/Transaction.js"
import DataTableWithFilters from "@/components/DataTableWithFilters.vue"
import router from "@/router"
import useModels from "@/states/models"
import { watch } from "vue"

export default {
  data() {
    return {
      columns: null,
      transactions: null
    }
  },
  created() {
    this.transactionService = new TransactionService()

    const { models, resetModels } = useModels()
    resetModels()

    watch(
      models,
      models => {
        if (models.Transaction && !this.transactionModel) {
          this.transactionModel = models.Transaction
          this.columns = Object.entries(this.transactionModel).reduce((acc, obj) => {
            acc[obj[0]] = { ...obj[1], value: null }
            return acc
          }, {})
        }
      },
      { immediate: true }
    )
  },
  async mounted() {
    const data = await this.transactionService.getTransactions()
    if (data) {
      data.map(v => {
        for (const key in v) {
          if (this.transactionModel?.[key]?.options) {
            if (Array.isArray(v[key])) {
              v[key].map((v2, index) => {
                v[key][index] = this.transactionModel[key].options.find(v3 => v3.value === v2)?.name || v2
              })
              v[key] = v[key].join(",")
            } else {
              v[key] = this.transactionModel[key].options.find(v2 => v2.value === v[key])?.name || v[key]
            }
          }

          v[key] = v[key] === true ? "Ναι" : v[key] === false ? "Όχι" : v[key]

          if (key === "amount") {
            v[key] = this.$i18n.n(v[key], "currency")
          }
        }
      })

      this.transactions = data
    }
  },
  methods: {
    goToTransaction(transaction) {
      router.push(`/transaction/${transaction._id}`)
    }
  },
  components: { DataTableWithFilters }
}
</script>
