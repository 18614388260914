<template>
  <User noBalance="true" noTransactions="true" />
</template>

<script>
import User from "@/views/User"

export default {
  components: { User }
}
</script>
