<template>
  <DataTableWithFilters
    :v-if="users && settings"
    :data="users"
    :columns="columns"
    :rowClick="goToUser"
    :rowClass="rowClass"
  ></DataTableWithFilters>
</template>

<script>
import UserService from "@/services/User.js"
import router from "@/router"
import DataTableWithFilters from "@/components/DataTableWithFilters"
import useModels from "@/states/models"
import { watch } from "vue"
import useSettings from "@/states/settings"

export default {
  data() {
    return {
      columns: null,
      users: null,
      settings: null,
      userMode: null
    }
  },
  created() {
    this.userService = new UserService()

    const { models, resetModels } = useModels()
    resetModels()
    watch(
      models,
      models => {
        if (models.User && !this.userModel) {
          this.userModel = models.User
          delete this.userModel.password
          if (!this.$auth.check("superAdmin")) {
            delete this.userModel.adminCapabilities
            delete this.userModel.adminNetworks
          }

          this.columns = this.userModel
        }
      },
      { immediate: true }
    )
  },
  mounted() {
    this.userService.getUsers().then(data => {
      data.map(v => {
        for (const key in v) {
          if (this.userModel?.[key]?.options) {
            if (Array.isArray(v[key])) {
              v[key].map((v2, index) => {
                v[key][index] = this.userModel[key].options.find(v3 => v3.value === v2)?.name || v2
              })
              v[key] = v[key].join(",")
            } else {
              v[key] = this.userModel[key].options.find(v2 => v2.value === v[key])?.name || v[key]
            }
          }

          v[key] = v[key] === true ? "Ναι" : v[key] === false ? "Όχι" : v[key]

          if (key === "balance") {
            v[key] = this.$i18n.n(v[key], "currency")
          }
        }
      })

      this.users = data
    })

    const { settings } = useSettings()
    watch(
      settings,
      settings => {
        if (Object.keys(settings).length > 0) {
          this.settings = settings

          let dynamicCSS = ""

          this.settings?.balanceLevels?.forEach((balanceLevel, index) => {
            dynamicCSS += `.p-datatable .p-datatable-tbody > tr.balance-level-${index} { background-color: #${balanceLevel["Χρώμα"]}; } `
          })

          this.dynamicStyleTag = document.createElement("style")
          this.dynamicStyleTag.appendChild(document.createTextNode(dynamicCSS))
          document.head.appendChild(this.dynamicStyleTag)
        }
      },
      { immediate: true }
    )
  },
  unmounted() {
    this.dynamicStyleTag?.remove()
  },
  methods: {
    //TODO: This is stupid. Find a way to fix the actual bug.
    focusOnInput(input) {
      setTimeout(() => {
        this.$refs[input][0].$el.focus()
      }, 1)
    },
    goToUser(user) {
      router.push(`/user/${user._id}`)
    },
    rowClass(data) {
      let rowClass = ""
      this.settings?.balanceLevels?.forEach((balanceLevel, index) => {
        const unformattedBalance = parseFloat(data.balance.replace("€", "").split(".")[0].replace(",", ""))
        const upperLimit =
          balanceLevel["Έως"] !== undefined ? parseFloat(balanceLevel["Έως"]) : Number.MAX_VALUE
        if (
          !rowClass &&
          (balanceLevel["Από"] === undefined ||
            (unformattedBalance >= parseFloat(balanceLevel["Από"]) && unformattedBalance <= upperLimit))
        ) {
          rowClass = `balance-level-${index}`
        }
      })
      return rowClass
    }
  },
  components: { DataTableWithFilters }
}
</script>
