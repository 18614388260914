<template>
  <div class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex align-items-center justify-content-between">
          <div class="font-medium text-3xl text-900 mb-3">Ρυθμίσεις Εφαρμογής</div>
          <div>
            <Button
              v-if="!isEditing && $auth.check('superAdmin')"
              @click="this.isEditing = true"
              type="button"
              label="Επεξεργασία"
              icon="pi pi-pencil"
              class="p-2 p-button-secondary"
            />
            <Button
              v-if="isEditing"
              @click="save()"
              type="button"
              label="Αποθήκευση"
              icon="pi pi-save"
              class="p-2 p-button-primary mr-2"
            />
            <Button
              v-if="isEditing"
              @click="cancel()"
              type="button"
              label="Ακύρωση"
              class="p-2 p-button-danger"
            />
          </div>
        </div>
        <Divider />
        <ul class="list-none p-0 m-0">
          <li
            v-for="(model, key) in settingsModel"
            :key="key"
            class="flex align-items-center border-top-1 border-bottom-1 surface-border flex-wrap"
            :class="{
              'py-4': !isEditing
            }"
          >
            <div class="text-500 md:w-4 sm:w-full font-medium">{{ model.niceName }}</div>
            <div class="text-900 md:w-8 sm:w-full md:flex-order-0 flex-order-1">
              <CustomModelValue :model="model" :value="settingsData[key]" v-if="!isEditing" />
              <CustomModelInput
                v-if="isEditing"
                :modelValue="settingsData[key]"
                @update:modelValue="newValue => (settingsData[key] = newValue)"
                :type="model.frontType"
                :properties="model.properties"
                :disabled="model.disabled"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button"
import Divider from "primevue/divider"
import SettingsService from "@/services/Settings"
import { watch } from "vue"
import useModels from "@/states/models.js"
import CustomModelInput from "@/components/CustomModelInput"
import CustomModelValue from "@/components/CustomModelValue"

export default {
  data() {
    return {
      settingsModel: null,
      settingsData: null,
      originalSettingsData: null,
      isEditing: false
    }
  },
  created() {
    this.settingsData = {}

    this.settingsService = new SettingsService()

    const { models, resetModels } = useModels()
    resetModels()
    watch(
      models,
      models => {
        if (!this.settingsModel && models.Settings) {
          this.settingsModel = models.Settings
        }
      },
      { immediate: true }
    )
  },
  mounted() {
    this.settingsService.getSettings().then(data => {
      this.originalSettingsData = { ...data }
      this.settingsData = data
    })
  },
  methods: {
    async save() {
      Object.entries(this.settingsModel).forEach(settingsModelProperty => {
        if (settingsModelProperty[1]?.frontType === "list") {
          this.settingsData[settingsModelProperty[0]] = this.settingsData[settingsModelProperty[0]].filter(
            v => v && (settingsModelProperty[1]?.properties === undefined || Object.keys(v).length > 0)
          )
        }
      })
      const success = await this.settingsService.setSettings(this.settingsData)
      if (success) {
        this.$toast.add({
          severity: "success",
          detail: "Η αποθήκευση πραγματοποιήθηκε επιτυχώς.",
          life: 3000,
          group: "noClick"
        })
        this.isEditing = false
        this.$root.apiCallInProgress = true
        this.$router.go()
      }
    },
    cancel() {
      this.settingsData = { ...this.originalSettingsData }
      this.isEditing = false
    }
  },
  components: {
    Button,
    Divider,
    CustomModelInput,
    CustomModelValue
  }
}
</script>
