<template>
  <div v-if="userData && userModel" class="flex">
    <div v-if="!noProfile" class="flex-1">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex align-items-center justify-content-between">
          <div class="font-medium text-3xl text-900 mb-3">Στοιχεία Χρήστη</div>
          <div>
            <Button
              v-if="
                !isEditing &&
                (($auth.user()?._id !== userData._id && $auth.check(['manageUsers'])) ||
                  $auth.check(['superAdmin']))
              "
              @click="this.isEditing = true"
              type="button"
              label="Επεξεργασία"
              icon="pi pi-pencil"
              class="p-2 p-button-secondary"
            />
            <Button
              v-if="isEditing"
              @click="save()"
              type="button"
              label="Αποθήκευση"
              icon="pi pi-pencil"
              class="p-2 p-button-primary mr-2"
            />
            <Button
              v-if="isEditing"
              @click="cancel()"
              type="button"
              label="Ακύρωση"
              class="p-2 p-button-danger"
            />
          </div>
        </div>
        <Divider />
        <ul class="list-none p-0 m-0">
          <template v-for="(model, key) in userModel" :key="key">
            <li
              v-if="
                !['hidden'].includes(model.frontType) &&
                ((key !== 'adminCapabilities' && key !== 'adminNetworks') || userData['role'] === 'admin')
              "
              :key="key"
              class="flex align-items-center py-2 px-2 flex-wrap"
              :class="{ 'border-top-1 surface-border': !isEditing }"
            >
              <div class="text-500 md:w-4 sm:w-full font-medium">{{ model.niceName }}</div>
              <div class="text-900 md:w-8 sm:w-full md:flex-order-0 flex-order-1">
                <CustomModelValue :model="model" :value="userData[key]" v-if="!isEditing" />
                <CustomModelInput
                  v-if="isEditing"
                  v-model="userData[key]"
                  :type="model.frontType"
                  :options="model.options"
                  :disabled="model.disabled"
                />
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div
      v-if="
        (!$auth.user().role.includes('superAdmin') || $auth.user()?._id !== userData._id) &&
        (!noBalance || !noTransactions)
      "
      :class="noProfile ? 'col-12' : 'col-6'"
    >
      <Balance
        v-if="!noBalance"
        :balance="userBalance"
        :transactions="transactions"
        :payment-methods="paymentMethods"
        :userId="userData._id"
        @manualTransactionCompleted="fetchUser"
      ></Balance>
      <div v-if="!noTransactions" class="surface-card shadow-2 p-3 border-round mt-5">
        <div class="font-medium text-3xl text-900 mb-3">Συναλλαγές Χρήστη</div>
        <Divider />
        <DataTableWithFilters
          :v-if="transactions"
          :data="transactions"
          :columns="transactionColumns"
          :rowClick="goToTransaction"
        ></DataTableWithFilters>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button"
import Divider from "primevue/divider"
import UserService from "@/services/User"
import DataTableWithFilters from "@/components/DataTableWithFilters"
import Balance from "@/components/Balance"
import { watch } from "vue"
import useModels from "@/states/models.js"
import CustomModelInput from "@/components/CustomModelInput"
import CustomModelValue from "@/components/CustomModelValue"
import router from "@/router"

export default {
  props: ["noProfile", "noBalance", "noTransactions"],
  data() {
    return {
      userModel: null,
      transactionModel: null,
      originalUserData: null,
      userData: null,
      userBalance: null,
      transactions: [],
      transactionColumns: {},
      isEditing: false
    }
  },
  created() {
    if (this.$route.params._id === this.$auth.user()._id) {
      router.push("/profile")
    } else {
      this.userService = new UserService()

      const { models, resetModels } = useModels()
      resetModels()
      watch(
        models,
        models => {
          if (models.User && !this.userModel) {
            this.userModel = models.User
          }

          if (models.Transaction && !this.transactionModel) {
            this.transactionModel = models.Transaction
            delete this.transactionModel.metaData
            this.transactionColumns = Object.entries(this.transactionModel).reduce((acc, obj) => {
              if (obj[0] !== "user") {
                acc[obj[0]] = { ...obj[1], value: null }
              }
              return acc
            }, {})
          }
        },
        { immediate: true }
      )
    }
  },
  computed: {
    paymentMethods() {
      return this.userData.paymentMethods || []
    }
  },
  mounted() {
    this.fetchUser()
  },
  watch: {
    "userData.role": {
      handler: function (newRole) {
        if (newRole !== "admin") {
          this.userData["adminCapabilities"] = this.originalUserData["adminCapabilities"]
          this.userData["adminNetworks"] = this.originalUserData["adminNetworks"]
        }
      }
    }
  },
  methods: {
    async save() {
      const success = await this.userService.updateUser(this.userData)
      if (success) {
        this.fetchUser()
        this.$toast.add({
          severity: "success",
          detail: "Η αποθήκευση πραγματοποιήθηκε επιτυχώς.",
          life: 3000,
          group: "noClick"
        })
        this.isEditing = false
      }
    },
    cancel() {
      this.userData = { ...this.originalUserData }
      this.isEditing = false
    },
    async fetchUser() {
      const data = await this.userService.getUser(this.$route.params._id)
      if (data) {
        this.originalUserData = { ...data.userData }
        this.userData = data.userData

        if (this.userData._id !== this.$auth.user()?._id) {
          this.userModel.role.options = this.userModel?.role?.options?.filter(v => v.value !== "superAdmin")
        }

        if (this.userData.role === "superAdmin") {
          delete this.userModel.status
          delete this.userModel.fullname
          delete this.userModel.company
          delete this.userModel.taxId
          delete this.userModel.address
          delete this.userModel.mobile
          delete this.userModel.iban
          delete this.userModel.associateCode
          delete this.userModel.bbAssociateCode
          delete this.userModel.network
          delete this.userModel.overchargePlafond
          delete this.userModel.paymentMethods
          delete this.userModel.hasSetPassword
          delete this.userModel.createdAt

          this.userModel.role.frontType = "readOnly"
        }

        this.userBalance = data.balance

        if (data.transactions) {
          data.transactions.map(v => {
            for (const key in v) {
              if (this.transactionModel?.[key]?.options) {
                if (Array.isArray(v[key])) {
                  v[key].map((v2, index) => {
                    v[key][index] = this.transactionModel[key].options.find(v3 => v3.value === v2)?.name || v2
                  })
                  v[key] = v[key].join(",")
                } else {
                  v[key] = this.transactionModel[key].options.find(v2 => v2.value === v[key])?.name || v[key]
                }
              }

              if (key === "amount") {
                v[key] = this.$i18n.n(v[key], "currency")
              }
            }
          })

          this.transactions = data.transactions
        }
      }
    },
    goToTransaction(transaction) {
      router.push(`/transaction/${transaction._id}`)
    }
  },
  components: {
    DataTableWithFilters,
    Button,
    Balance,
    Divider,
    CustomModelInput,
    CustomModelValue
  }
}
</script>

<style scoped lang="scss">
.p-column-filter-row .p-column-filter-element {
  width: auto;
}
</style>
