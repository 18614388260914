<template>
  <div>
    <Calendar v-model="from" dateFormat="mm/dd/yy" placeholder="Από" />
    <Calendar v-model="to" dateFormat="mm/dd/yy" placeholder="Έως" />
  </div>
</template>

<script>
import Calendar from "primevue/calendar"

export default {
  props: ["modelValue"],
  emits: ["update:modelValue"],
  data() {
    return {
      from: this.modelValue?.[0],
      to: this.modelValue?.[1]
    }
  },
  watch: {
    from: {
      handler: function () {
        this.$emit("update:modelValue", [this.from, this.to])
      }
    },
    to: {
      handler: function () {
        this.$emit("update:modelValue", [this.from, this.to])
      }
    }
  },
  components: { Calendar }
}
</script>
