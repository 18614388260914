<template>
  <div
    class="footer w-full p-2 border-top-1 surface-border bg-white bottom-0 flex justify-content-between align-items-center"
  >
    <h1 class="text-500 text-2xl font-light m-0">Prepaid</h1>
    <a
      class="font-medium text-primary no-underline ml-2 text-right cursor-pointer"
      @click="this.displayInfoModal = true"
    >
      Πληροφορίες
    </a>
    <a
      href="https://moosend-templates.insurancemarket.gr/EnergyMarket/PDF/insurance-pro.gr.pdf"
      target="_blank"
      class="font-medium text-primary no-underline ml-2 text-right cursor-pointer"
      >Όροι Χρήσης</a
    >
    <div class="flex align-items-center"><img style="height: 45px" src="@/assets/logo.png" /></div>
  </div>
  <div class="footer w-full border-top-1 surface-border bg-white text-center p-2">
    <small
      >Money Market A.E. Ευριπίδου 31-33 Αθήνα 10554 τηλ: 2120005151 e-mail:
      <a class="text-color-secondary" href="mailto:support@insurance-pro.gr"
        >support@insurance-pro.gr</a
      ></small
    >
  </div>
  <Dialog
    :modal="true"
    header="Τι είναι το prepaid.insurance-pro.gr;"
    v-model:visible="displayInfoModal"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '60vw' }"
  >
    <div class="confirmation-content">
      <div class="flex align-content-center">
        <div class="align-self-center">
          H υπηρεσία prepaid.insurance-pro.gr είναι μία παρεπόμενη υπηρεσία στα πλαίσια της κύριας συνεργασίας
          της Μοney Market S.A. με επαγγελματίες ασφαλιστικούς πράκτορες, η οποία αφορά στην διανομή
          ασφαλιστικών προϊόντων συνεργαζόμενων με την πρώτη ασφαλιστικών επιχειρήσεων, μέσω της της
          ηλεκτρoνικής πλατφόρμας Insurance Pro. Μέσω της υπηρεσίας αυτής ενεργοποιούνται μέσω της ALPHA
          Τράπεζας – υπηρεσίας Alpha e-commerce - ρυθμίσεις υπηρεσίας on line διασύνδεσης σε επιχειρήσεις Β2Β.
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog"

export default {
  data() {
    return {
      displayInfoModal: false
    }
  },
  components: {
    Dialog
  }
}
</script>
