<template>
  <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div class="text-700 text-center">
      <div class="text-900 font-bold text-5xl mb-3">Αυτή η σελίδα δεν υπάρχει</div>
      <div class="text-700 text-2xl mb-5">
        Περίεργο που καταλήξατε εδώ. Αν πιστεύετε ότι είναι πρόβλημα του συστήματος, παρακαλούμε επικοινωνήστε
        μαζί μας.
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
