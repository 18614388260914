<template>
  <div
    class="loader top-0 left-0 h-full w-full flex align-items-center bg-white-alpha-50"
    v-if="apiCallInProgress"
  >
    <ProgressSpinner />
  </div>
  <Layout />
  <Toast group="noClick" position="bottom-center" />
  <div v-click-outside="closeAllToasts">
    <Toast position="bottom-center" />
  </div>
</template>

<style>
html,
body,
#app {
  height: 100%;
}

body {
  margin-top: 0;
  margin-bottom: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.p-divider-solid.p-divider-horizontal:before {
  border-top-style: solid !important;
}
.p-divider-dashed.p-divider-horizontal:before {
  border-top-style: dashed !important;
}
.p-divider-solid.p-divider-vertical:before {
  border-left-style: solid !important;
}

.p-toast .p-toast-message .p-toast-message-content {
  align-items: center;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin-top: 0 !important;
}

.loader {
  position: absolute;
  z-index: 1102;
}
</style>

<script>
import Layout from "@/components/Layout.vue"
import Toast from "primevue/toast"
import axios from "axios"
import { useToast } from "primevue/usetoast"
import router from "@/router"
import useModels from "@/states/models"
import useSettings from "@/states/settings"
import ProgressSpinner from "primevue/progressspinner"

export default {
  name: "App",
  data() {
    return {
      apiCallInProgress: false
    }
  },
  created() {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
    const toast = useToast()

    axios.interceptors.request.use(
      config => {
        this.apiCallInProgress = true
        return config
      },
      error => {
        this.apiCallInProgress = false
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      response => {
        this.apiCallInProgress = false

        if (response?.data?.success === false) {
          toast.add({ severity: "error", detail: response.data.error.details, group: "noClick" })
        }
        return response
      },
      err => {
        if (err.response?.status === 401) {
          router.push("/login")
        } else {
          toast.add({
            severity: "error",
            detail:
              "Πρόβλημα δικτύου. Παρακαλούμε ελέγξτε τη σύνδεσή σας με το internet και προσπαθήστε ξανά.",
            group: "noClick"
          })
        }
        this.apiCallInProgress = false
        return Promise.reject(err)
      }
    )

    router.beforeEach((to, from, next) => {
      if (to.path !== "/reset_password" && this.$auth.check() && !this.$auth?.user()?.hasSetPassword) {
        next("/reset_password")
      } else {
        next()
      }
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (undefined !== this.$route.query.transactionFailed) {
          this.$toast.add({
            severity: "error",
            detail: "Η πληρωμή απέτυχε. Παρακαλούμε δοκιμάστε ξανά."
          })
          // eslint-disable-next-line no-unused-vars
          const { transactionFailed, ...newQuery } = this.$route.query
          this.$router.replace({ newQuery })
        } else if (undefined !== this.$route.query.transactionSucceededButInsertFailed) {
          this.$toast.add({
            severity: "error",
            detail: "Η καταχώρηση της πληρωμής απέτυχε. Παρακαλούμε επικοινωνήστε μαζί μας."
          })
          // eslint-disable-next-line no-unused-vars
          const { transactionSucceededButInsertFailed, ...newQuery } = this.$route.query
          this.$router.replace({ newQuery })
        }

        const DEFAULT_TITLE = "Profia Prepaid"
        document.title = `${to.name || "Loading"} | ${DEFAULT_TITLE}`
      }
    },
    "$auth.$vm.state": {
      immediate: true,
      deep: true,
      async handler() {
        if (this.$auth.$vm.state.data && Object.keys(this.$auth.$vm.state.data).length) {
          if (
            this.$auth.$vm.state.data.hasSetPassword === false &&
            router.currentRoute.value.path !== "/reset_password"
          ) {
            router.push("/reset_password")
          } else {
            const modelsResponse = await axios.get("/models")

            if (modelsResponse?.data?.success) {
              const { setModels } = useModels()
              setModels(modelsResponse.data.data)
            }

            if (this.$auth.check("superAdmin")) {
              const settingsResponse = await axios.get("/settings")

              if (settingsResponse?.data?.success) {
                const { setSettings } = useSettings()
                setSettings(settingsResponse.data.data)
              }
            }
          }
        }
      }
    }
  },
  methods: {
    closeAllToasts() {
      this.$toast.removeGroup("noClick")
    }
  },
  components: { Layout, Toast, ProgressSpinner }
}
</script>
