<template>
  <div class="h-auto px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div class="text-center mb-5">
        <img src="@/assets/logo.png" alt="Image" height="50" class="mb-3" />
      </div>

      <div>
        <div class="font-medium mb-5 text-center pb-5 surface-border border-bottom-1">
          Παρακαλούμε χρησιμοποιήστε τα παρακάτω πεδία για να ορίσετε νέο κωδικό σύνδεσης και στη συνέχεια
          πατήστε το κουμπί "Επαναφορά".
        </div>

        <label for="password" class="block text-900 font-medium mb-2">Νέος κωδικός σύνδεσης</label>
        <Password
          id="password"
          v-on:keyup.enter="reset"
          class="w-full mb-3"
          inputClass="w-full"
          v-model="password"
          strongRegex="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
          promptLabel=" "
          weakLabel="Μη αποδεκτός κωδικός"
          mediumLabel="Μη αποδεκτός κωδικός"
          strongLabel="Αποδεκτός κωδικός"
          ><template #footer>
            <Divider />
            <p class="mt-4">Κριτήρια</p>
            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
              <li>Τουλάχιστον 8 χαρακτήρες</li>
              <li>Τουλάχιστον ένα μικρό γράμμα</li>
              <li>Τουλάχιστον ένα κεφαλαίο γράμμα</li>
              <li>Τουλάχιστον έναν αριθμό</li>
              <li>Τουλάχιστον ένα σύμβολο</li>
            </ul>
          </template></Password
        >

        <label for="password2" class="block text-900 font-medium mb-2"
          >Επιβεβαίωση νέου κωδικού σύνδεσης</label
        >
        <InputText
          v-on:keyup.enter="reset"
          v-model="password2"
          id="password2"
          type="password"
          class="w-full mb-3"
        />

        <Button label="Επαναφορά" icon="pi pi-user" class="w-full" @click="reset"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext"
import router from "@/router"
import ResetPasswordService from "@/services/ResetPassword"
import UserService from "@/services/User"
import Password from "primevue/password"

export default {
  data() {
    return {
      username: null,
      password: null,
      password2: null,
      resetPasswordService: null,
      userService: null
    }
  },
  created() {
    if (this.$route.params.resetToken) {
      this.resetPasswordService = new ResetPasswordService()
    } else {
      this.userService = new UserService()
    }

    if (this.$auth.check() && !this.$auth.user()?.hasSetPassword) {
      router.push("/")
    }
  },
  methods: {
    async reset() {
      if (!this.password || !this.password2) {
        this.$toast.add({
          severity: "warn",
          detail: "Παρακαλούμε συμπληρώστε όλα τα πεδία.",
          life: 3000
        })
      } else if (this.password !== this.password2) {
        this.$toast.add({
          severity: "error",
          detail: "Οι κωδικοί δεν ταιριάζουν.",
          life: 3000
        })
      } else {
        if (this.$route.params.resetToken) {
          const success = await this.resetPasswordService.resetPassword(
            this.$route.params.resetToken,
            this.password
          )
          if (success) {
            this.$toast.add({
              severity: "success",
              detail: "Η επαναφορά κωδικού ολοκληρώθηκε. Μπορείτε να συνδεθείτε με τον νέο κωδικό σας.",
              life: 3000
            })

            router.push("/login")
          }
        } else {
          const success = await this.userService.updateUser({
            _id: this.$auth.user()?._id,
            password: this.password
          })
          if (success) {
            this.$toast.add({
              severity: "success",
              detail: "Η επαναφορά κωδικού ολοκληρώθηκε. Μπορείτε να συνδεθείτε με τον νέο κωδικό σας.",
              life: 3000
            })
            this.$auth.logout()
          }
        }
      }
    }
  },
  components: { InputText, Password }
}
</script>
